<template>
  <div class="stock-check">
    <titlebar :name="name" :is_custom_back="true" @goBackCustom="goBack" />

    <div class="page_box">
      <div class="flex flex-ac ft-26 mg-bt30">
        <div>备注：</div>
        <input v-model="remark" class="check-remark-input" />
      </div>
      <table
        width="100%"
        id="table"
        style="border-collapse: collapse"
        border="0"
        cellspacing="0"
        cellpadding="0"
      >
        <thead bgcolor="#F2F2F2">
          <tr
            bgcolor="#F2F2F2"
            class="thead-tr"
            style="height: 8rem;"
          >
            <td class="color-333 ft-24 text-center">名称</td>
            <td class="color-333 ft-24 text-center">分类</td>
            <td class="color-333 ft-24 text-center">单位</td>
            <td class="color-333 ft-24 text-center">当前库存</td>
            <td class="color-333 ft-24 text-center">盘点后库存</td>
          </tr>
        </thead>

        <tbody v-if="check_list.length > 0" class="relative">
          <tr v-for="(item, index) in check_list" class="color-333 ft-24 text-center tbody-tr relative">
            <td>{{ item.name }}</td>
            <td>{{ item.c_name }}</td>
            <td>{{ item.dw }}</td>
            <td>{{ item.stock }}</td>
            <td>
              <input v-if="platform_name == 'android'" class="stock-input" v-model="item.after_stock" placeholder="请输入" readonly @click="openKeyboard(item, index)" />
              <input v-else class="stock-input" v-model="item.after_stock" placeholder="请输入" type="number" />
            </td>
          </tr>
        </tbody>
      </table>
      <div style="height: 13rem;"></div>
    </div>

    <div class="foot_btn_box">
      <van-button class="foot_btn" style="margin-right: 10rem;" @click="goBack()">取消</van-button>
      <van-button class="foot_btn foot_submit" @click="saveCheck()">保存</van-button>
    </div>

    <van-number-keyboard 
      v-model="numVal" 
      :title="numVal" 
      :show="show_keyboard" 
      extra-key="." 
      @blur="show_keyboard = false;" 
      @input="handleNumChange" 
      @delete="handleNumDelete"
      class="otherEatNum-nk" 
    />
  </div>
</template>
<script>
import titlebar from "@/views/component/titlebar";
export default {
  name: 'stock-check',
  components: {
    titlebar,
  },
  data() {
    return {
      name: 'stockCheck',
      check_list: [],
      remark: '',
      numberIndex: -1, // 页面数据参数
      numVal: '', // 数字键盘显示金额
      numValArr: [], // 数字键盘金额数组
      show_keyboard: false,
      platform_name: localStorage.nowDevice || '',
    }
  },
  computed: {
    stock_check_list() {
      return this.$store.state.stock_check_list;
    },
  },
  created() {
    this.check_list = this.stock_check_list || [];
  },
  methods: {
    handleNumChange(e) {
			this.numValArr.push(e);
			this.numVal = this.numValArr.join('');
			this.check_list[this.numberIndex].after_stock = this.numVal;
		},
		handleNumDelete(e) {
			this.numValArr.pop();
			this.numVal = this.numValArr.length > 0 ? this.numValArr.join('') : '';
			this.check_list[this.numberIndex].after_stock = this.numVal;
		},
    openKeyboard(item, index) {
      this.numberIndex = index;
      this.numVal = item.after_stock ? item.after_stock.toString() : '';
      this.numValArr = item.after_stock ? item.after_stock.toString().split('') : [];
      this.show_keyboard = true;
    },
    goBack() {
      this.$router.replace({
        name: 'featureSet',
        query: {
          name: 'featureSet',
          active: this.$route.query.active,
        },
        params: {
          tab_id: this.$route.query.tab_id,
        },
      })
    },
    saveCheck() {
      console.log('this.check_list', this.check_list);
      let arr = [];
      for (let i = 0; i < this.check_list.length; i++) {
        const element = this.check_list[i];
        if (!element.after_stock || element.after_stock == '') {
          this.$toast(`${element.name}请输入库存`);
          return;
        }
        arr.push({
          id: element.id,
          name: element.name,
          cate: element.c_name,
          dw: element.dw,
          front: element.stock,
          after: element.after_stock,
          shop_id: element.s_id || localStorage.shop_id,
        })
      }
      // this.check_list.forEach(element => {
      //   arr.push({
      //     id: element.id,
      //     name: element.name,
      //     cate: element.c_name,
      //     dw: element.dw,
      //     front: element.stock,
      //     after: element.after_stock,
      //     shop_id: element.s_id || localStorage.shop_id,
      //   })
      // });
      let query = {
        mark: this.remark,
        param: JSON.stringify(arr),
      }
      this.$api.inventoryLog_addInventoryLog(query).then(res => {
        this.$toast(res.msg);
        if (res.code == 1) {
          this.goBack();
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
  .otherEatNum-nk {
    border-radius: 0.13rem;
    width: 30vw;
    left: 35vw;
    z-index: 9999;
  }
  .stock-check {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .foot_btn_box {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #fff;
    z-index: 10;
    height: 10rem;
    align-items: center;
    box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.26);
    left: 0;

    .foot_btn {
      font-size: 2.4rem;
      width: 42rem;
      height: 7rem;
      border-radius: 0.8rem;
      background: #eaeaea;
    }

    .foot_submit {
      color: #fff;
      background-color: #1588f5;
    }
  }

  .page_box {
    position: relative;
    height: calc(100% - 17rem);
    background: #fff;
    padding: 3rem 3rem;
    margin-top: 1.5rem;
    overflow-y: scroll;
    overflow-x: hidden;

    .check-remark-input {
      width: 50%;
      height: 6.2rem;
      border: 0.2rem solid #DEDEDE;
      border-radius: 0.8rem;
      margin-left: 2.4rem;
      padding: 0 2rem;
      font-size: 2.4rem;
    }

    table {
      background: white;
      display: table;
      width: 100%;

      thead {
        width: 100%;
        display: block;
        display: table;
        table-layout: fixed;
      }

      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        vertical-align: middle;
      }
      
      tbody {
        display: block;
        width: 100%;
      }
      .tbody-tr {
        overflow-y: scroll;
        height: 8rem;
        width: 100%;
        border-bottom: 0.2rem rgba(0, 0, 0, 0.06) solid;
        &.odd {
          background: #fef7ef;
        }
        td {
          height: 8rem;
        }

        .stock-input {
          width: 40%;
          height: 6rem;
          border: 0.2rem solid #DEDEDE;
          border-radius: 0.8rem;
          padding: 0 2rem;
          font-size: 2.4rem;
        }
      }
    }
  }
</style>